@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap");

:root {
  --font-base: "Montserrat", sans-serif;

  --primary-color: #edf2f9;
  --secondary-color: #6078e9;
  --black-color: #030303;
  --lightGray-color: #e4e4e4;
  --gray-color: #6b7688;
  --brown-color: #46364a;
  --white-color: #ffffff;
}

.dark{
    --primary-color: #0b0b17;
    --secondary-color: #6078e9;
    --black-color: #f8f6f6;
    --lightGray-color: #e4e4e4;
    --gray-color: #6b7688;
    --brown-color: #46364a;
    --white-color: #ffffff;
    --bg-color: #000;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  user-select: none;
}
